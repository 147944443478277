<template>
  <el-dialog :title="isEdit ? '编辑' : '新增'" width="600px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="90px" :rules="rules">
      <el-form-item label="插件名称" prop="pluginName">
        <el-input v-model="form.pluginName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="插件编码" prop="pluginCode">
        <el-input v-model="form.pluginCode" placeholder="请输入" :disabled="isEdit"></el-input>
      </el-form-item>
      <el-form-item label="插件类型" prop="operationKind">
        <el-input v-model="form.operationKind" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="yaml资源">
        <el-input v-model="form.useYamlSourceUrl" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="插件类别">
        <el-input v-model="form.category" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="镜像简称">
        <el-input v-model="form.chart" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="镜像版本">
        <el-input v-model="form.chatVersion" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="插件版本">
        <el-input v-model="form.version" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="镜像地址">
        <el-input v-model="form.chartRepoUrl" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="iconUrl">
        <image-upload :url.sync="form.iconUrl" @success="handleAvatarSuccess" :params="{ businessType: 'system_icon' }" :fileSize="2" fileType="image" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" type="textarea" placeholder="请输入"> </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Api from '@/api/devops/dictionary'
import imageUpload from '@/components/imageUpload'
export default {
  components: { imageUpload },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: () => {},
      type: Object,
    },
    isEdit: { type: Boolean, default: false },
  },
  watch: {
    show(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.detail)
        this.form = this.isEdit ? this.detail : {}
      } else {
        this.reset()
      }
    },
  },
  data() {
    return {
      form: {},
      rules: {
        pluginName: [{ required: true, message: '请输入插件名称', trigger: 'blur' }],
        pluginCode: [{ required: true, message: '请输入插件编码', trigger: 'blur' }],
        operationKind: [{ required: true, message: '请输入插件类型', trigger: 'blur' }],
      },
    }
  },
  methods: {
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const fn = this.form.id ? Api.updatePlugIn : Api.addPlugIn
          fn(this.form).then(() => {
            this.$msg.success('操作成功')
            this.reset()
            this.close()
          })
        }
      })
    },
    reset() {
      this.$refs['form']?.resetFields()
    },
    close() {
      this.$emit('close')
    },
    handleAvatarSuccess(res) {
      const { ossUrl } = res.data
      this.$set(this.form, 'iconUrl', ossUrl)
    },
  },
}
</script>
