<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="插件名称">
            <el-input placeholder="请输入" v-model="form.pluginName" clearable></el-input>
          </el-form-item>
          <el-form-item label="插件编码">
            <el-input placeholder="请输入" v-model="form.pluginCode" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <template slot-scope="scope">
              <el-button type="primary" @click="getData">查询</el-button>
              <el-button type="primary" plain @click="handleAdd('add', scope.row)">新增</el-button>
            </template>
          </el-form-item>
        </el-form>
      </template>
      <template #default>
        <el-table ref="table" :data="cardData" v-loading="tableLoading" border stripe height="100%">
          <el-table-column prop="pluginName" label="插件名称" align="center" min-width="150" />
          <el-table-column prop="pluginCode" label="插件编码" align="center" min-width="150" />
          <el-table-column prop="iconUrl" label="图标" align="center" min-width="100">
            <template slot-scope="scope" v-if="scope.row.iconUrl">
              <el-image :src="scope.row.iconUrl" :preview-src-list="[scope.row.iconUrl]" style="max-width: 70px; height: 70px" />
            </template>
          </el-table-column>
          <el-table-column prop="operationKind" label="插件类型" align="center" min-width="150" />
          <el-table-column prop="useYamlSourceUrl" label="yaml资源" align="center" min-width="150" />
          <el-table-column prop="category" label="插件类别" align="center" min-width="150" />
          <el-table-column prop="chart" label="镜像简称" align="center" min-width="150" />
          <el-table-column prop="chatVersion" label="镜像版本" align="center" min-width="150" />
          <el-table-column prop="version" label="插件版本" align="center" min-width="150" />
          <el-table-column prop="chartRepoUrl" label="镜像地址" align="center" min-width="150" />
          <el-table-column prop="remark" label="备注" align="center" min-width="200" />
          <el-table-column label="操作" align="center" fixed="right" width="150">
            <template slot-scope="scope">
              <el-button type="text" @click="handleAdd('detail', scope.row)">详情</el-button>
              <el-button type="text" @click="handleAdd('edit', scope.row)">编辑</el-button>
              <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.pageSize" :current-page="page.pageNum" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <add-modal :isEdit="isEdit" :show="addDrawer" @close="closeModal" :detail="rowData"></add-modal>
    <el-drawer title="详情" :visible.sync="detailDrawer" :append-to-body="true" size="30%" direction="rtl" @close="detailDrawer = false">
      <detail-modal :detail="rowData"></detail-modal>
    </el-drawer>
  </div>
</template>
<script>
import addModal from './addModal'
import detailModal from './detailModal'
import Api from '@/api/devops/dictionary'
export default {
  components: { addModal, detailModal },
  data() {
    return {
      form: {},
      rowData: null,
      cardData: [],
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      addDrawer: false,
      detailDrawer: false,
      isEdit: false,
      tableLoading: false,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      let { pageSize, pageNum } = this.page
      let { pluginCode, pluginName } = this.form
      let params = {
        current: pageNum,
        size: pageSize,
        pluginCode,
        pluginName,
      }
      this.tableLoading = true
      let { code, data } = await Api.getPlugInList(params)
      if (data.records) {
        this.cardData = data.records || []
        this.total = data.total
      }
      this.tableLoading = false
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.getData()
    },
    handleAdd(type, row) {
      switch (type) {
        case 'add':
          this.addDrawer = true
          this.detailDrawer = false
          this.rowData = {}
          this.isEdit = false
          break
        case 'edit':
          this.addDrawer = true
          this.detailDrawer = false
          this.isEdit = true
          this.rowData = { ...row }
          break
        case 'detail':
          this.detailDrawer = true
          this.addDrawer = false
          this.rowData = { ...row }
          break
      }
    },
    handleEdit() {},
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        Api.dePlugIn(row.id).then(() => {
          this.$msg.success('操作成功')
          this.getData()
        })
      })
    },
    handleDeleteAll() {
      let idList = this.selectionData.map((item) => item.dictionaryId).join(',')
      this.handleDelete(idList)
    },
    closeModal() {
      this.addDrawer = false
      this.getData()
    },
  },
}
</script>
