<template>
  <div class="font12 ml10" style="line-height: 28px; color: #999; font-style: italic">
    <div>插件名称：{{ detail.pluginName }}</div>
    <div>插件编码：{{ detail.pluginCode }}</div>
    <div>插件类型：{{ detail.operationKind }}</div>
    <div>yaml资源：{{ detail.useYamlSourceUrl }}</div>
    <div>插件类别：{{ detail.category }}</div>
    <div>镜像简称：{{ detail.chart }}</div>
    <div>镜像版本：{{ detail.chatVersion }}</div>
    <div>插件版本：{{ detail.version }}</div>
    <div>镜像地址：{{ detail.chartRepoUrl }}</div>
    <div>备注：{{ detail.remark }}</div>
    <div>创建人：{{ detail.createdBy }}</div>
    <div>创建时间：{{ detail.createTime }}</div>
    <div>修改人：{{ detail.updatedBy }}</div>
    <div>修改时间：{{ detail.updateTime }}</div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: () => {},
      type: Object,
    },
  },
}
</script>
